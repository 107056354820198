.portfolio-caption-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
}

.modal-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
