// Styling for the masthead
header.masthead {
    text-align: center;
    color: $primary;
    background-image: url('../assets/img/header-bg.jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    .intro-text {
        padding-top: 150px;
        padding-bottom: 100px;
        .intro-lead-in {
            font-size: 22px;
            font-style: italic;
            line-height: 22px;
            margin-bottom: 25px;
            @include serif-font;
        }
        .intro-heading {
            font-size: 40px;
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 25px;
            text-shadow: -1px 5px 0 #000;
            @include heading-font;
        }
    }
}
@media (min-width: 768px) {
    header.masthead {
        .intro-text {
            padding-top: 300px;
            padding-bottom: 200px;
            .intro-lead-in {
                font-size: 40px;
                font-style: italic;
                line-height: 40px;
                margin-bottom: 25px;
                @include serif-font;
            }
            .intro-heading {
                font-size: 75px;
                font-weight: 700;
                line-height: 75px;
                margin-bottom: 50px;
                text-shadow: -2px 8px 0 #000;
                @include heading-font;
            }
        }
    }
}
