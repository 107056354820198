// Styling for the services section
section#services p {
    text-align: justify;
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}
.service-heading-light {
    margin: 15px 0;
    text-transform: none;
    color: white;
}
