.service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    font-size: 50px;
    line-height: 100px;
    background-color: var(--primary);
    color: white;
}

ul.social-buttons li a {
    font-size: 0 !important;
}

// Override to show Calendar tooltips
div.tooltip {
    opacity: 1;
}